import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivityIndicatorModule } from '../activity-indicator/activity-indicator.module';
import { IconModule } from '../icon/icon.module';
import { ColorThemeModule } from '../shared/color-theme/color-theme.module';
import { ButtonComponent } from './button.component';
import { KurzTooltipModule } from '@util/directives/kurz-tooltip/kurz-tooltip.module';

@NgModule({
  declarations: [
    ButtonComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    ColorThemeModule,
    ActivityIndicatorModule,
    KurzTooltipModule
  ],
  exports: [
    ButtonComponent,
    ColorThemeModule,
  ]
})
export class ButtonModule { }
